import { useMemo } from 'react';

import { useLocation } from './use-location';

type NavigationOptions = { replace?: boolean };
type UseSearchParamsResult = [
  Record<string, string | null>,
  {
    set: (
      key: string,
      value: string | null,
      options?: NavigationOptions
    ) => void;
    remove: (key: string, options?: NavigationOptions) => void;
    urlWith(params: Record<string, string | null>): string;
    urlWithout(params: string[]): string;
  },
];

export const useSearchParams = (): UseSearchParamsResult => {
  const location = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(location.search);

    const urlWith = (params: Record<string, string | null>) => {
      const newParams = new URLSearchParams(location.search);
      Object.entries(params).forEach(([key, value]) => {
        if (!value && value !== '') newParams.delete(key);
        else newParams.set(key, value);
      });
      return `${location.pathname}?${newParams.toString()}`;
    };
    const urlWithout = (params: string[]) => {
      const newParams = new URLSearchParams(location.search);
      params.forEach((key) => newParams.delete(key));
      return `${location.pathname}?${newParams.toString()}`;
    };
    const set = (
      key: string,
      value: string | null,
      { replace }: NavigationOptions = {}
    ) => {
      if (typeof window === 'undefined') return;
      const url = urlWith({ [key]: value });
      if (replace) {
        window.history.replaceState(null, '', url);
      } else {
        window.history.pushState(null, '', url);
      }
    };
    const remove = (key: string, { replace }: NavigationOptions = {}) => {
      if (typeof window === 'undefined') return;
      const url = urlWithout([key]);
      if (replace) {
        window.history.replaceState(null, '', url);
      } else {
        window.history.pushState(null, '', urlWithout([key]));
      }
    };

    return [
      Object.fromEntries(params.entries()),
      { set, remove, urlWith, urlWithout },
    ];
  }, [location.search]);
};
